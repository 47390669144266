<template>
<div>
     <v-row justify="center" v-if="bookings.summary">
                <v-col cols="12" sm="12" md="6" lg="4" class="mx-0 px-0">
                    <v-list subheader>
                        <v-subheader style="background: #8f8f8f" class="primaryText--text">
                            <h3>Booking Statuses</h3>
                        </v-subheader>
                        <v-list-item v-for="state in bookings.summary.state" :key="state.key">
                            <v-list-item-action>
                                <v-icon :color="getIconColor(state.key)">{{getIcon(state.key)}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{state.key}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{state.count}}
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" class="mx-0 px-0">
                    <v-list subheader>
                        <v-subheader style="background: #8f8f8f" class="primaryText--text">
                            <h3>Bookings by Carrier</h3>
                        </v-subheader>
                        <v-list-item v-for="carrier in bookings.summary.carrierId" :key="carrier.key">
                            <v-list-item-avatar>
                                <v-img contain :src="'/shippingLines/'+ getImage(carrier.keyAsString)"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{carrier.keyAsString}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip>{{carrier.count}}</v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" class="mx-0 px-0">
                    <v-list class="ma-0 pa-0" subheader>
                        <v-subheader style="background: #8f8f8f" class="primaryText--text">
                            <h3>Bookings Champions</h3>
                        </v-subheader>
                        <div style="max-height: 600px; overflow-y:auto">
                            <v-list-item dense v-for="(champion, index) in bookings.summary.createdBy.filter(x=>x.key != 'null')" :key="champion.key" style="border-bottom: 1px solid lightgrey">
                                <v-list-item-action>
                                    <v-icon v-if="index<=2" :color="index ==0 ? '#FFD700': index ==1?'#C0C0C0': '#CD7F32'">workspace_premium</v-icon>
                                    <span v-else>{{index+1}}th</span>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{champion.key}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip>{{champion.count}}</v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </div>

                    </v-list>
                </v-col>
            </v-row>

</div>
</template>

<script>
export default {
    props:['bookings'] ,
    data: () => ({
        carrierData: null,
        // bookings: Object,
        loaded: false,
        key: 0
    }),
    methods: {
        formatTime(time) {
            let result = null
            if (time) {
                result = new Date(time).toISOString().
                replace(/T/, ' '). // replace T with a space
                replace(/\..+/, '').substr(0, 16)
            }
            return result
        },
        formatUtcTime(time) {
            let result = null
            if (time) {
                result = new Date(time).toISOString().
                replace(/T/, ' '). // replace T with a space
                replace(/\..+/, '')
            }
            return result
        },
        getIcon(state) {
            switch (state) {
                case 'Confirmed':
                    return 'check_circle_outline'
                case 'Declined':
                    return 'highlight_off'
                case 'Requested':
                    return 'pending'
                case 'Cancelled':
                    return 'block'
                case 'Replaced':
                    return 'sync'
                case 'Amended':
                    return 'edit'
            }
        },
        getIconColor(state) {
            switch (state) {
                case 'Confirmed':
                    return 'green'
                case 'Declined':
                    return 'red'
                case 'Requested':
                    return 'blue'
                case 'Cancelled':
                    return 'red'
                case 'Replaced':
                    return 'orange'
                case 'Amended':
                    return 'grey'
            }
        },
        getImage(state) {
            switch (state) {
                case 'MSC':
                    return 'msc.png'
                case 'Maersk':
                    return 'maersk.png'
                case 'Ocean Network Express Pte. Ltd.':
                    return 'one.png'
                case 'DAL':
                    return 'dal.png'
                case 'HAPAG-LLOYD':
                    return 'hapag-lloyd.png'
                case 'CMA CGM':
                    return 'cma-cgm.png'
                case 'PIL':
                    return 'pil.png'
                case 'HAMBURG SÜD':
                    return 'hamburg.jpeg'
            }
        }
    }
}
</script>
